import React from "react";
import logoSrc from "../../../static/2020/svg/logo.svg";
import beeSrc from "../../../static/2020/images/impactcall/bee.svg";
import shieldSrc from "../../../static/2020/images/impactcall/shield.svg";
import badgeSrc from "../../../static/2020/images/impactcall/badge.svg";
import docSrc from "../../../static/2020/images/impactcall/doc.svg";
import {
  StyledHeader,
  StyledHeaderLogo,
  StyledHeaderNav,
  StyledHeaderNavItem,
} from "./style";
import useScrollController from "../../hooks/useScrollController";

const scrollTargets = ["#promotore", "#contest", "#premio", "#regolamento"];

export const Header = () => {
  const { scrollToCssSelector } = useScrollController();

  return (
    <StyledHeader>
      <StyledHeaderLogo href="/">
        <img src={logoSrc} loading="lazy" />
      </StyledHeaderLogo>
      <StyledHeaderNav>
        <StyledHeaderNavItem first onClick={() => scrollToCssSelector(scrollTargets[0], 160)}>
          <img src={beeSrc} loading="lazy" />
          Il Promotore
        </StyledHeaderNavItem>
        <StyledHeaderNavItem second onClick={() => scrollToCssSelector(scrollTargets[1], 160)}>
          <img src={shieldSrc} loading="lazy" />
          Contest
        </StyledHeaderNavItem>
        <StyledHeaderNavItem thirth onClick={() => scrollToCssSelector(scrollTargets[2], 160)}>
          <img src={badgeSrc} loading="lazy" />
          Premio
        </StyledHeaderNavItem>
        <StyledHeaderNavItem fourth onClick={() => scrollToCssSelector(scrollTargets[3], 160)}>
          <img src={docSrc} loading="lazy" />
          Regolamento
        </StyledHeaderNavItem>
      </StyledHeaderNav>
    </StyledHeader>
  );
};
